import React from 'react';
import classNames from 'classnames';
import {IProduct, ProductType} from '../../../../types/galleryTypes';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';
import {BaseGalleryStore} from '../../../../viewerScript/BaseGalleryStore';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  SrPriceRange = 'st-price-range',
  OutOfStock = 'product-item-out-of-stock',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
  PriceRange = 'price-range-from',
  BasePriceComponent = 'base-price-component',
}

interface TextMapProps {
  productPriceBeforeDiscountSR: string;
  productOutOfStockText: string;
  productPriceAfterDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
  measurementUnits?: {[key: string]: {[key: string]: string}};
  pricePerUnitSR?: string;
}

export interface IProductPriceProps extends IGalleryGlobalProps {
  product: IProduct;
  allowFreeProducts: boolean;
  textsMap: TextMapProps;
  isAddtoCartButtonExists: boolean;
  fromPrice?: string;
  priceBreakdown: BaseGalleryStore['priceBreakdown'];
  sendClickShippingInfoLinkSf: Function;
  isRTL: boolean;
  isMobile: boolean;
  isGalleryPreOrderWhenADCButtonDisabled: boolean;
  shouldUseXSSInPriceBreakdown: boolean;
  showAutomaticDiscountDataOnGallery: boolean;
}

const getPriceBeforeDiscount = ({textsMap, product, showAutomaticDiscountDataOnGallery}) => {
  const priceBeforeDiscount =
    showAutomaticDiscountDataOnGallery && product.itemDiscount?.priceAfterDiscount
      ? product.formattedComparePrice || product.formattedPrice
      : product.formattedPrice;
  return (
    <>
      <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
        {textsMap.productPriceBeforeDiscountSR}
      </span>
      <span data-hook={DataHook.PriceBeforeDiscount} className={s.priceBeforeDiscount}>
        {priceBeforeDiscount}
      </span>
    </>
  );
};

class PriceRangeComp extends React.Component<
  {formattedFromPrice: string; textsMap: TextMapProps} & IProvidedTranslationProps
> {
  public render() {
    const {formattedFromPrice, textsMap, t} = this.props;
    return (
      <>
        <span className={a11y.srOnly} data-hook={DataHook.SrPriceRange}>
          {textsMap.productPriceWhenThereIsNoDiscountSR}
        </span>
        <span data-hook={DataHook.PriceRange} className={s.priceFrom}>
          {t('priceRangeText', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }
}

const PriceRange = withGlobals(withTranslations()(PriceRangeComp));

const RegularPrice = ({hasDiscount, product, textsMap, showAutomaticDiscountDataOnGallery}) => {
  const discountPrice = showAutomaticDiscountDataOnGallery
    ? product.itemDiscount?.priceAfterDiscount || product.formattedComparePrice
    : product.formattedComparePrice;
  return (
    <>
      {hasDiscount && getPriceBeforeDiscount({textsMap, product, showAutomaticDiscountDataOnGallery})}
      {
        <>
          <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
            {hasDiscount ? textsMap.productPriceAfterDiscountSR : textsMap.productPriceWhenThereIsNoDiscountSR}
          </span>
          <span data-hook={DataHook.PriceToPay} className={s.priceToPay}>
            {hasDiscount ? discountPrice : product.formattedPrice}
          </span>
        </>
      }
    </>
  );
};

const BasePriceContainer = ({product, textsMap}) => {
  const getBasePriceTranslationSR = (translation, vars) => {
    //eslint-disable-next-line prefer-named-capture-group
    return translation.replace(/\{\{([^}]+)\}\}/gi, (_match, k) => {
      return vars[k.trim()];
    });
  };

  const {
    formattedPricePerUnit,
    pricePerUnitData: {baseQuantity, baseMeasurementUnit},
  } = product;

  const noun = baseQuantity === 1 ? 'singular' : 'plural';
  const unitTranslation = textsMap.measurementUnits[baseMeasurementUnit].abbr;
  const screenReaderText = getBasePriceTranslationSR(textsMap.pricePerUnitSR, {
    basePrice: formattedPricePerUnit,
    units: `${baseQuantity} ${textsMap.measurementUnits[baseMeasurementUnit][noun]}`,
  });
  return (
    <BasePrice
      data-hook={DataHook.BasePriceComponent}
      className={s.basePrice}
      formattedPricePerUnit={formattedPricePerUnit}
      baseQuantity={baseQuantity}
      unitTranslation={unitTranslation}
      screenReaderText={screenReaderText}
      withRTLSupport
    />
  );
};

const BasePriceWithGlobals = withGlobals(BasePriceContainer);
export class ProductPrice extends React.Component<IProductPriceProps, any> {
  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {product, sendClickShippingInfoLinkSf} = this.props;
    sendClickShippingInfoLinkSf(product.id);
  };

  private hasDiscount() {
    const {product, showAutomaticDiscountDataOnGallery} = this.props;
    return (
      product.discount?.value > 0 || (showAutomaticDiscountDataOnGallery && product.itemDiscount?.priceAfterDiscount)
    );
  }

  public render() {
    const {
      product,
      allowFreeProducts,
      isAddtoCartButtonExists,
      textsMap,
      fromPrice,
      isRTL,
      isMobile,
      isGalleryPreOrderWhenADCButtonDisabled,
      shouldUseXSSInPriceBreakdown,
      showAutomaticDiscountDataOnGallery,
    } = this.props;
    const {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer} = this.props.priceBreakdown;

    const isOutOfStock = !product.isInStock;
    const shouldRenderPrices = product.price !== 0 || allowFreeProducts;
    const hasDiscount = this.hasDiscount();
    const noRenderWhenDiscountedToZero = !(!allowFreeProducts && product.comparePrice === 0 && hasDiscount);
    const shouldRenderBasePrice = !!product.formattedPricePerUnit;
    const isPreOrderItem = product.inventory.availableForPreOrder;

    if (isGalleryPreOrderWhenADCButtonDisabled) {
      if (isOutOfStock && !isAddtoCartButtonExists && !isPreOrderItem) {
        return (
          <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
            {textsMap.productOutOfStockText}
          </span>
        );
      }
    } else if (isOutOfStock && !isAddtoCartButtonExists) {
      return (
        <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
          {textsMap.productOutOfStockText}
        </span>
      );
    }

    if (!shouldRenderPrices || !noRenderWhenDiscountedToZero) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(s.prices, {
            [s.mobile]: isMobile,
          })}>
          {fromPrice ? (
            <PriceRange formattedFromPrice={fromPrice} textsMap={textsMap} />
          ) : (
            <RegularPrice
              showAutomaticDiscountDataOnGallery={showAutomaticDiscountDataOnGallery}
              hasDiscount={hasDiscount}
              product={product}
              textsMap={textsMap}
            />
          )}
        </div>
        {shouldRenderBasePrice && <BasePriceWithGlobals product={product} textsMap={textsMap} />}
        {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
          <PriceBreakdown
            shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
            taxDisclaimerLabel={taxDisclaimer}
            shippingDisclaimer={shippingDisclaimer}
            isDigitalProduct={product.productType === ProductType.DIGITAL}
            whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
            isRTL={isRTL}
            className={s.priceBreakdown}
            shouldUseXSSInPriceBreakdown={shouldUseXSSInPriceBreakdown}
          />
        )}
      </>
    );
  }
}
