import {
  classes as categoriesBreadcrumbsStylable,
  st as categoriesBreadcrumbsStyles,
} from './CategoriesBreadcrumbs.st.css';
import React from 'react';
import {Breadcrumbs} from 'wix-ui-tpa';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {DataHook} from '../CategoriesApp/CategoriesApp';
import classNames from 'classnames';

export const CategoriesBreadcrumbs: React.FunctionComponent<IGalleryGlobalProps> = withGlobals(
  (props: IGalleryGlobalProps) => {
    const {breadcrumbsHistory, shouldShowMobile, currentCategory, handleCategoryBreadcrumbsClick} = props.globals;
    const classes = classNames(
      categoriesBreadcrumbsStyles(categoriesBreadcrumbsStylable.breadcrumbsWrapper, {
        mobile: shouldShowMobile,
      })
    );
    return (
      <div data-hook={DataHook.Breadcrumbs} className={classes}>
        <Breadcrumbs
          items={breadcrumbsHistory}
          onClick={
            /* istanbul ignore next: will be refactored when bi event will be updated*/ (item) =>
              handleCategoryBreadcrumbsClick({item, originCategoryId: currentCategory.id})
          }
          className={classNames(
            categoriesBreadcrumbsStyles(categoriesBreadcrumbsStylable.breadcrumbs, {
              mobile: shouldShowMobile,
            })
          )}
          data-hook={DataHook.BreadcrumbsComponent}
          showTrailOnMobileMode={true}
        />
      </div>
    );
  }
);
